/** @jsx jsx */
import { Box, Grid, Flex, jsx } from 'theme-ui';
import {
  Container,
  RichText,
  Media,
  ImageBlock,
  BackgroundImage,
} from '@sprinklr/shared-lib';
import { CallToAction } from '@sprinklr/shared-lib';
import { LoginTemplateProps } from './types';

import { MEDIA_QUERY } from '@sprinklr/shared-lib/theme/mediaQuery';

const LoginTemplate = (props: LoginTemplateProps) => {
  const {
    loginBackgroundImage,
    loginHeadline,
    loginBodyCopy,
    loginCtas,
    mediaType,
    featuredImage,
    externalVideoUrl,
    hasInvertedText,
  } = props;

  let mediaProps;
  if (mediaType === 'IMAGE')
    mediaProps = {
      mediaType: mediaType,
      source: featuredImage,
      mediaSx: {
        '.gatsby-image-wrapper': {
          borderRadius: 2,
          boxShadow: 4,
          img: {
            objectFit: 'cover !important',
            minHeight: '100%',
            minWidth: '100%',
          },
        },
      },
    };
  if (mediaType === 'VIDEO')
    mediaProps = {
      mediaType: mediaType,
      externalVideoUrl: externalVideoUrl,
      isFullWidthVideo: true,
    };

  const iframeUrl =
    typeof window !== 'undefined' && document?.location
      ? new URL(document?.location?.toString())
      : undefined;
  const deviceParam = iframeUrl?.searchParams?.get('dv');
  const shouldDisplayContent = !(deviceParam === 'tb' || deviceParam === 'mb');

  return (
    <Flex
      sx={{
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative',
        zIndex: 0,
      }}
    >
      {loginBackgroundImage ? (
        <BackgroundImage image={loginBackgroundImage} />
      ) : null}
      {shouldDisplayContent && (
        <Container
          sx={{
            width: '100%',
            padding: '24px !important',
            '@media screen and (min-width: 520px)': {
              padding: '64px !important',
            },
            [MEDIA_QUERY.md]: {
              padding: '24px !important',
            },
            '@media screen and (min-width: 920px)': {
              padding: '64px !important',
            },
          }}
        >
          <Grid
            sx={{
              alignItems: 'center',
              width: '100%',
              gridTemplateColumns: '1fr',
              gridGap: '16px',
              '@media screen and (min-width: 640px)': {
                gridGap: '32px',
              },
              [MEDIA_QUERY.md]: {
                gridTemplateColumns: '1fr 1fr',
                gridGap: '32px',
              },
            }}
          >
            <Box
              sx={{
                '*': { color: hasInvertedText ? 'white !important' : null },
                'h1, h2': {
                  fontWeight: 'bold',
                  fontSize: '32px',
                  lineHeight: '38px',
                  [MEDIA_QUERY.md]: {
                    fontSize: '34px',
                    lineHeight: '40px',
                  },
                  [MEDIA_QUERY.lg]: {
                    fontSize: '40px',
                    lineHeight: '48px',
                  },
                },
                'h1, h2, h3, h4, h5, h6': {
                  mb: '22px',
                },
                p: {
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 500,
                  [MEDIA_QUERY.md]: {
                    fontSize: '16px',
                    lineHeight: '24px',
                  },
                  [MEDIA_QUERY.lg]: {
                    fontSize: '18px',
                    lineHeight: '24px',
                  },
                },
              }}
            >
              <RichText richTextObject={loginHeadline} />
              {loginBodyCopy && <RichText richTextObject={loginBodyCopy} />}
              {loginCtas?.length > 0 && (
                <Flex sx={{ flexWrap: 'wrap' }}>
                  {loginCtas.map((cta, index) => {
                    const isLast = loginCtas.length - 1 === index;
                    const hasMoreThanOneCta = loginCtas.length > 1;
                    return (
                      <Box
                        key={cta.text}
                        sx={{
                          marginTop: '24px',
                          width: 'auto',
                          ...(!isLast && hasMoreThanOneCta
                            ? {
                                marginRight: '12px',
                                [MEDIA_QUERY.lg]: {
                                  marginRight: '16px',
                                },
                              }
                            : {}),
                          flex: '1 1 0%',
                        }}
                      >
                        <CallToAction
                          ctaSx={{
                            width: '100%',
                            paddingLeft: ['8px !important'],
                            paddingRight: ['8px !important'],
                          }}
                          {...cta}
                        />
                      </Box>
                    );
                  })}
                </Flex>
              )}
            </Box>
            <Box>
              {(mediaType === 'IMAGE' || mediaType === 'VIDEO') && (
                <Media {...mediaProps} />
              )}
            </Box>
          </Grid>
        </Container>
      )}
    </Flex>
  );
};

export default LoginTemplate;
